import axios, { AxiosResponse } from "axios";
import { IArticle, IAxiosResponse } from "types";
import { IArticleBody, IMediaBody } from "types/article.types";

export const createArticle = async (
  body: IArticleBody
): Promise<IAxiosResponse<IArticleBody & { id: number }>> => {
  return axios.post("capripedia/article-edit/", body);
};

export const updateArticle = async (data: {
  id: number;
  body: IArticleBody;
}): Promise<IAxiosResponse<IArticleBody & { id: number }>> => {
  return axios.patch(`capripedia/article-edit/${data.id}/`, data.body);
};

export const getArticle = async (id: number): Promise<IArticle> => {
  const { data } = await axios.get(`capripedia/article-edit/${id}/`);
  return data;
};

export const uploadFile = async (file: IMediaBody): Promise<AxiosResponse> => {
  return axios.post(`capripedia/article-media/`, file, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};
export const updateFile = async (data: {
  id: number;
  file: Partial<IMediaBody>;
}): Promise<AxiosResponse> => {
  return axios.patch(`capripedia/article-media/${data.id}/`, data.file, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const deleteFile = async (id: number) => {
  return axios.delete(`capripedia/article-media/${id}/`);
};

export const publishArticle = async (data: {
  id: number;
  publish: boolean;
}): Promise<AxiosResponse<{ status: "Unpublished" | "Published" }>> => {
  return axios.post(`capripedia/article-edit/${data.id}/publish/`, {
    publish: data.publish,
  });
};
