import { useMutation } from "@tanstack/react-query";
import { useLanguage } from "hooks/useLanguage.hook";
import { FormEvent, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { IArticle } from "types";
import { IArticleBody } from "types/article.types";
import { ArticleType } from "types/enums/articleType.enum";
import { isArticleError } from "utils/isValidationError.helper";
import { TabNavigation } from "../../articles/components/tabNavigation";
import { Divider } from "../../layout/components/divider.component";
import { ArticleEditHeader } from "../editor/components/articleEditHeader.component";
import { IPickedExpert } from "../editor/components/expertPicker.component";
import { MetaDataEditor } from "../editor/components/metaDataEditor.component";
import {
  ChapterEditor,
  IEditChapter,
} from "./components/chapterEdit.component";
import { ImageUpload } from "./components/imageUpload.component";
import { updateArticle } from "./editArticle.query";

export const UpdateArticleEditor = ({ article }: { article: IArticle }) => {
  const { articleId } = useParams<{ articleId?: string }>();
  const language = useLanguage();
  const {
    mutate,
    isLoading: isUpdating,
    error: createErrors,
  } = useMutation({
    mutationFn: updateArticle,
    onSuccess: () => {
      setAlert({
        variant: "success",
        text: "Het artikel is successvol bijgewerkt.",
      });
    },
    onError: () => {
      setAlert({
        variant: "danger",
        text: "Er is iets misgegaan. Kijk de ingevoerde gegevens na.",
      });
    },
  });

  const [alert, setAlert] = useState<
    { text: string; variant: "success" | "danger" } | undefined
  >(undefined);
  const [title, setTitle] = useState(article.title);
  const [domain, setDomain] = useState(
    `${article.subdomain.domain.id}/${article.subdomain.id}`
  );
  const [invalidDomain, setInvalidDomain] = useState(false);
  const [linkWords, setLinkWords] = useState(
    article.link_name_set.map((linkName) => linkName.link_name).join(", ")
  );
  const [chapters, setChapters] = useState<IEditChapter[]>([
    { title: "", body: "", isFree: true, active: true },
  ]);
  const [selectedExperts, setSelectedExperts] = useState<IPickedExpert[]>(
    article.article_author
      .sort((a, b) => a.order - b.order)
      .map((expert) => ({
        id: expert.author.id,
        name: `${expert.author.user.first_name} ${expert.author.user.last_name}`,
      }))
  );

  const save = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (domain === "-1") {
      setInvalidDomain(true);
    }
    const sudomainId = Number(domain.split("/")[1]);
    const body: IArticleBody = {
      subdomain: sudomainId,
      type: ArticleType.article,
      article_author: selectedExperts.map((expert, i) => ({
        author: expert.id,
        order: i,
      })),
      chapters: chapters.map((chapter) => ({
        is_free: chapter.isFree,
        language,
        body: chapter.body,
        title: chapter.title,
      })),
      link_name_set: linkWords.split(",").map((linkName) => ({
        link_name: linkName.trim(),
        language,
      })),
      article_translation_set: [{ title, language }],
    };
    mutate({ body, id: Number(articleId) });
  };

  const errors = isArticleError(createErrors) ? createErrors.response.data : {};

  return (
    <article className={"edit-article"}>
      <Form onSubmit={save}>
        {alert ? (
          <Alert
            className="alert"
            variant={alert.variant}
            dismissible
            onClose={() => setAlert(undefined)}
          >
            {alert.text}
          </Alert>
        ) : null}

        <ArticleEditHeader
          article={article}
          isUpdating={isUpdating}
          title={title}
          setTitle={setTitle}
          error={errors}
          setAlert={setAlert}
        />
        <div className={"edit-body"}>
          <MetaDataEditor
            type={ArticleType.article}
            disabled={isUpdating}
            invalidDomain={invalidDomain}
            domain={domain}
            errors={errors}
            linkWords={linkWords}
            selectedExperts={selectedExperts}
            setDomain={(newDomain) => {
              setDomain(newDomain);
              setInvalidDomain(false);
            }}
            setLinkWords={setLinkWords}
            setSelectedExperts={setSelectedExperts}
          />
          <ChapterEditor
            article={article}
            chapters={chapters}
            setChapters={setChapters}
            errors={errors}
            disabled={isUpdating}
          />
        </div>
      </Form>
      <div className={"edit-body"}>
        <Divider />
        <TabNavigation className={"overflow-auto"} titles={["Foto's"]} />
        <ImageUpload />
      </div>
    </article>
  );
};
