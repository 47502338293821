import { TabNavigation } from "features/articles/components/tabNavigation";
import { Divider } from "features/layout/components/divider.component";
import { useLanguage } from "hooks/useLanguage.hook";
import { useEffect } from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { IArticle } from "types";
import { IArticleError } from "types/article.types";
import { getTranslation } from "utils/getTranslation.helper";
import { TextEditor } from "./textEditor.component";

export type IEditChapterActive = IEditChapter & { active: boolean };
export const ChapterEditor = ({
  setChapters,
  chapters,
  errors,
  disabled,
  article,
}: {
  setChapters(arg: IEditChapter[] | ((chapters: IEditChapter[]) => void)): void;
  chapters: IEditChapter[];
  errors: IArticleError;
  disabled: boolean;
  article?: IArticle;
}) => {
  const language = useLanguage();

  useEffect(() => {
    if (article) {
      const chapters: IEditChapter[] = article.chapter_set.map((chapter) => ({
        title: chapter.title,
        body:
          getTranslation(chapter.chapter_translation_set, language)?.body ?? "",
        isFree: chapter.is_free,
        active: false,
      }));
      chapters[0].active = true;
      setChapters(chapters);
    }
  }, [article, language, setChapters]);

  const addChapter = () => {
    setChapters((chapters) => {
      return chapters
        .map((chapter) => ({ ...chapter, active: false }))
        .concat([{ title: "", body: "", isFree: false, active: true }]);
    });
  };

  const chapter = chapters.find((chapter) => chapter.active) as IEditChapter;

  const setChapterTitle = (title: string) => {
    setChapters((chapters: IEditChapter[]) =>
      chapters.map((chapter) => {
        return chapter.active ? { ...chapter, title } : { ...chapter };
      })
    );
  };
  const setBody = (body: string, chapterId: number) => {
    setChapters((chapters) =>
      chapters.map((chapter) => {
        return chapter.active ? { ...chapter, body } : chapter;
      })
    );
  };
  const setIsFree = (isFree: boolean) => {
    setChapters((chapters) =>
      chapters.map((chapter) => {
        return chapter.active ? { ...chapter, isFree } : { ...chapter };
      })
    );
  };
  const deleteChapter = () => {
    if (chapters.length <= 1) {
      window.alert("Het laatste hoofdstuk kan niet verwijderd worden.");
      return;
    }

    const index = chapters.findIndex((chapter) => chapter.active);

    const newActiveIndex = index === 0 ? 0 : index - 1;
    const newChapters = chapters
      .filter((_, i) => i !== index)
      .map((chapter, i) =>
        i === newActiveIndex ? { ...chapter, active: true } : chapter
      );
    setChapters(newChapters);
  };

  const setActiveChapter = (index: number) => {
    setChapters((chapters) =>
      chapters.map((chapter, i) => {
        return i === index
          ? { ...chapter, active: true }
          : { ...chapter, active: false };
      })
    );
  };

  const activeChapter = chapters.findIndex((chapter) => chapter.active);
  const chapterErrors = errors?.chapters?.[activeChapter];
  return (
    <div>
      <Divider alignText={"right"}>
        <Button
          variant={"link"}
          className={"add-chapter-btn"}
          onClick={addChapter}
          disabled={disabled}
        >
          + Hoofdstuk toevoegen
        </Button>
      </Divider>
      <TabNavigation
        activeIndex={activeChapter}
        titles={chapters.map((chapter, i) =>
          chapter.title === "" ? `Hoofdstuk ${i + 1}` : chapter.title
        )}
        className={"overflow-auto"}
        onTabClick={setActiveChapter}
        errors={errors.chapters}
      />
      <ChapterEdit
        chapterId={activeChapter}
        disabled={disabled}
        title={chapter.title}
        body={chapter.body}
        isFree={chapter.isFree}
        errors={chapterErrors}
        setTitle={setChapterTitle}
        setBody={setBody}
        setIsFree={setIsFree}
        onDelete={deleteChapter}
      />
    </div>
  );
};

export const ChapterEdit = (props: {
  chapterId: number;
  title: string;
  body: string;
  isFree: boolean;
  setTitle(title: string): void;
  setBody(body: string, chapterId: number): void;
  setIsFree(isFree: boolean): void;
  onDelete(): void;
  errors?: { body?: string[]; title?: string[] };
  disabled?: boolean;
}) => {
  return (
    <div className={"chapter-edit"}>
      <div className={"edit-chapter-title-cont"}>
        <FormGroup className={"form-group"}>
          <FormControl
            disabled={props.disabled}
            isInvalid={!!props.errors?.title}
            type={"text"}
            placeholder={"Hoofdstuk titel"}
            value={props.title}
            onChange={(e) => props.setTitle(e.target.value)}
          />
        </FormGroup>
        <FormGroup className={"form-group free"}>
          <Form.Check
            id="is-free"
            disabled={props.disabled}
            type={"checkbox"}
            label={"Gratis beschikbaar"}
            checked={props.isFree}
            onChange={(e) => props.setIsFree(!props.isFree)}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <TextEditor
          chapterId={props.chapterId}
          disabled={props.disabled}
          isInvalid={!!props.errors?.body}
          className={"text-editor"}
          value={props.body}
          setText={props.setBody}
        />
      </FormGroup>
      <Button
        variant={"danger"}
        className={"delete-chapter"}
        onClick={props.onDelete}
      >
        Verwijderen
      </Button>
    </div>
  );
};

export interface IEditChapter {
  title: string;
  body: string;
  isFree: boolean;
  active: boolean;
}
